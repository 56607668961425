define("discourse/plugins/discourse-homepage-filter/discourse/initializers/discourse-homepage-filter", ["exports", "discourse/lib/plugin-api", "discourse/models/nav-item"], function (_exports, _pluginApi, _navItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-homepage-filter",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("1.34.0", api => {
        api.addNavigationBarItem({
          name: "home",
          customFilter: (category, args, router) => {
            if (category || args.tagId) {
              return false;
            }
            return true;
          },
          customHref: (category, args) => {
            return _navItem.default.pathFor("home", args);
          },
          before: "latest"
        });
      });
    }
  };
});